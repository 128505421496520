import PaymentLinks from './PaymentLinks';
import Transactions from './Transactions';
import Balance from './Balance';
import SellerWallet from './Wallet';
import Transfers from './Transfers';
import Companies from './Companies';

export default {
  PaymentLinks,
  Transactions,
  Balance,
  SellerWallet,
  Transfers,
  Companies,
};
