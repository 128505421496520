/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialStateType = {
  loading: boolean;
  errors: boolean;
  paymentLink: IPayments.IPaymentLink;
  paymentLinksList: IPayments.IPaymentLink[];
  totalLinks: number;
  showDeleteModal: boolean;
  selectedPaymentLink: string | undefined;
};

const initialState: IInitialStateType = {
  loading: false,
  errors: false,
  paymentLink: {} as IPayments.IPaymentLink,
  paymentLinksList: [] as IPayments.IPaymentLink[],
  totalLinks: 0,
  showDeleteModal: false,
  selectedPaymentLink: undefined,
};

const paymentLinks = createSlice({
  name: 'PaymentLinks',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    createPaymentLinksRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createPaymentLinksSuccess: state => {
      state.loading = false;
    },
    createPaymentLinksError: state => {
      state.loading = false;
      state.errors = true;
    },
    getPaymentLinkRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getPaymentLinkSuccess: (
      state,
      action: PayloadAction<IPayments.IPaymentLink>,
    ) => {
      state.loading = false;
      state.paymentLink = action.payload;
    },
    getPaymentLinkError: state => {
      state.loading = false;
      state.errors = true;
    },
    getPaymentLinksRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getPaymentLinksSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.paymentLinksList = action.payload.links;
      state.totalLinks = action.payload.total;
    },
    getPaymentLinksError: state => {
      state.loading = false;
      state.errors = true;
    },
    deletePaymentLinkRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    deletePaymentLinkSuccess: state => {
      state.loading = false;
      state.showDeleteModal = false;
      state.selectedPaymentLink = undefined;
    },
    deletePaymentLinkError: state => {
      state.loading = false;
      state.errors = true;
      state.showDeleteModal = false;
      state.selectedPaymentLink = undefined;
    },
    showDeleteModal: (
      state,
      action: PayloadAction<{ id?: string; show: boolean }>,
    ) => {
      state.showDeleteModal = action.payload.show;
      state.selectedPaymentLink = action.payload.id;
    },
  },
});

export const {
  clearState,
  createPaymentLinksError,
  createPaymentLinksRequest,
  createPaymentLinksSuccess,
  getPaymentLinkRequest,
  getPaymentLinkSuccess,
  getPaymentLinkError,
  getPaymentLinksRequest,
  getPaymentLinksSuccess,
  getPaymentLinksError,
  deletePaymentLinkRequest,
  deletePaymentLinkError,
  deletePaymentLinkSuccess,
  showDeleteModal,
} = paymentLinks.actions;

export default paymentLinks.reducer;
