import { call, put } from 'redux-saga/effects';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  getBalanceSuccess,
  getBalanceError,
} from '@store/Reducers/Sellers/Balance';

export function* getBalanceRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/sellers/balance');
    if (response.data) {
      yield put(getBalanceSuccess(response.data));
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getBalanceError());
    }
  }
}
