import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialStateType = {
  loading: boolean;
};

const initialState: IInitialStateType = {
  loading: false,
};

const basic = createSlice({
  name: 'Basic',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { clearState, setLoading } = basic.actions;

export default basic.reducer;
