import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import history from '@hooks/useHistory';

import {
  getRolesSuccess,
  getRolesError,
  getPermissionsSuccess,
  getPermissionsError,
  createRoleSuccess,
  createRoleError,
  showRoleSuccess,
  showRoleError,
  updateRoleSuccess,
  updateRoleError,
  deleteRoleSuccess,
} from '@store/Reducers/Admin/AdminRoles';

export function* getRolesSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/users/roles');
    yield put(getRolesSuccess(response.data));
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getRolesError());
    }
  }
}

export function* getPermissionsSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/users/roles/permissions');
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield put(getPermissionsSuccess(response.data));
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getPermissionsError());
    }
  }
}

export function* createRoleSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/users/roles/', payload);
    yield put(createRoleSuccess());

    if (response.data) {
      toastrRedux('Função criada com sucesso.', 'success');
      history.push('/admin/settings/roles');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createRoleError());
    }
  }
}

export function* deleteRoleSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.delete, `/users/roles/${payload.id}`);
    if (response.status === 204) {
      toastrRedux('Função deletada com sucesso.', 'success');
      yield put(deleteRoleSuccess(payload.id));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createRoleError());
    }
  }
}

export function* showRoleSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/users/roles/${payload.id}`);
    if (response.data) {
      yield put(showRoleSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(showRoleError());
    }
  }
}

export function* updateRoleSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const { id, ...roleData } = payload;
    const response = yield call(api.patch, `/users/roles/${id}`, roleData);
    yield put(updateRoleSuccess());

    if (response.data) {
      toastrRedux('Função atualizada com sucesso.', 'success');
      history.push('/admin/settings/roles');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(updateRoleError());
    }
  }
}
