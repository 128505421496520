import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialStateType = {
  loading: boolean;
  errors: boolean;
};

const initialState: IInitialStateType = {
  loading: false,
  errors: false,
};

const transfers = createSlice({
  name: 'Transfers',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    transferRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    transferRequestSuccess: state => {
      state.loading = false;
    },
    transferRequestError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  transferRequest,
  transferRequestSuccess,
  transferRequestError,
} = transfers.actions;
export default transfers.reducer;
