/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialStateType = {
  loading: boolean;
  errors: boolean;
  orders: IPayments.IOrder[];
  order: IPayments.IOrder;
  totalItems: number;
};

const initialState: IInitialStateType = {
  loading: false,
  errors: false,
  orders: [] as IPayments.IOrder[],
  order: {} as IPayments.IOrder,
  totalItems: 0,
};

const transactions = createSlice({
  name: 'Transactions',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getOrdersRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getOrdersSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.orders = action.payload.orders;
      state.totalItems = action.payload.total;
    },
    getOrdersError: state => {
      state.loading = false;
    },
    getOrderRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getOrderSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.order = action.payload;
    },
    getOrderError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersError,
  getOrderRequest,
  getOrderSuccess,
  getOrderError,
} = transactions.actions;

export default transactions.reducer;
