import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import history from '@hooks/useHistory';

import {
  getPaymentDataSuccess,
  getPaymentDataError,
  createTransactionSuccess,
  createTransactionError,
  findConsumerSuccess,
  findConsumerError,
} from '@store/Reducers/Payments/Payments';

export function* getPaymentLinkDataSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/payments/links/${payload}`);
    if (!response.data.is_active) {
      yield put(getPaymentDataError());
      history.replace('/pay/404');
    } else {
      yield put(getPaymentDataSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getPaymentDataError());
    }
  }
}

export function* createTransactionRequestSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/payments/transactions', payload);
    if (response.data) {
      yield put(createTransactionSuccess());
      toastrRedux('Sucesso. Pagamento concluído', 'success');
      history.replace(
        `/pay/${payload.session_id}/success/?source=${payload.source}&source_id=${payload.source_id}`,
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(createTransactionError());
      toastrRedux(error.message, 'error');
      history.replace(
        `/pay/${payload.session_id}/failure/?source=${payload.source}&source_id=${payload.source_id}`,
      );
    }
  }
}

export function* findConsumerRequestSaga({
  payload,
}: PayloadAction): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/consumers/find', payload);
    if (response.status === 200) {
      yield put(findConsumerSuccess(response.data));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(findConsumerError());
      toastrRedux(error.message, 'error');
    }
  }
}
