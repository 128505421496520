/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getCompaniesSuccess,
  getCompaniesError,
  getCitiesSuccess,
  getPartnersCitiesSuccess,
  getCitiesError,
  getStatesSuccess,
  getStatesError,
  getCompanyDataSuccess,
  getCompanyDataError,
  getAddressSuccess,
  getAddressError,
  getPartnerAddressSuccess,
  getRolesSuccess,
  getRolesError,
  createSellerSuccess,
  createSellerError,
  showCompanySuccess,
  showCompanyError,
  updateCompanySuccess,
  updateCompanyError,
  approveSellerSuccess,
  approveSellerError,
} from '@store/Reducers/Admin/AdminCompanies';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import Helpers from '@helpers/Functions';
import history from '@hooks/useHistory';

export function* getCompaniesSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const { pagination, filters } = payload;
    const parsedFilters = Helpers.cleanEmptyObjectValues(filters);

    const response = yield call(api.get, '/companies', {
      params: { ...pagination, ...parsedFilters },
    });

    yield put(getCompaniesSuccess(response.data));
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
    }
    yield put(getCompaniesError());
  }
}

export function* getStatesSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/ibge/states');
    const states = response.data.map((state: any) => {
      return {
        label: state.nome,
        value: state.uf,
      };
    });

    yield put(getStatesSuccess(states));
  } catch (error: unknown) {
    yield put(getStatesError());
  }
}

export function* getCitiesSaga({
  payload,
}: PayloadAction<{ uf: string; userIndex?: number }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/ibge/states/${payload.uf}/cities`);
    const cities = response.data.map((city: any) => {
      return {
        label: city.nome,
        value: city.id,
      };
    });

    if (payload.userIndex !== undefined) {
      yield put(
        getPartnersCitiesSuccess({ cities, userIndex: payload.userIndex }),
      );
    } else {
      yield put(getCitiesSuccess(cities));
    }
  } catch (error: unknown) {
    yield put(getCitiesError());
  }
}

export function* getCompanyPublicDataSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/public/cnpj/${payload}`);
    yield put(getCompanyDataSuccess(response.data));
  } catch (error: unknown) {
    yield put(getCompanyDataError());
  }
}

export function* getAddressRequestSaga({
  payload,
}: PayloadAction<{ cep: string; userIndex?: number }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/public/cep/${payload.cep}`);
    if (payload.userIndex !== undefined) {
      yield put(
        getPartnerAddressSuccess({
          userIndex: payload.userIndex,
          address: response.data,
        }),
      );
    } else {
      yield put(getAddressSuccess(response.data));
    }
  } catch (error) {
    yield put(getAddressError());
  }
}
export function* getRolesRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/users/roles');

    const parsedResponse = response.data.map((role: any) => ({
      label: role.name,
      value: role.id,
    }));

    yield put(getRolesSuccess(parsedResponse));
  } catch (error) {
    yield put(getRolesError());
  }
}

export function* createSellerRequestSaga({
  payload,
}: PayloadAction<{ data: Object }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/sellers', payload.data);
    yield put(createSellerSuccess(response.data));
    toastrRedux('Seller criado com sucesso', 'success');
    history.push('/admin/companies');
  } catch (error) {
    yield put(createSellerError());
  }
}

export function* showCompanySagaRequest({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/companies/${payload}`);
    if (response.status !== 200) throw new Error(response.statusText);
    yield put(showCompanySuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(showCompanyError());
    }
  }
}

export function* updateCompanyRequest({
  payload: { companyId, data },
}: PayloadAction<{ companyId: string; data: any }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.patch, `/companies/${companyId}`, data);
    if (response.status !== 200) throw new Error(response.statusText);
    yield put(updateCompanySuccess(response.data));
    toastrRedux('Empresa atualizada com sucesso', 'success');
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(updateCompanyError());
    }
  }
}

export function* approveSellerRequestSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.patch, `/sellers/${payload}/approve`);
    if (response.status !== 200) throw new Error();
    yield put(approveSellerSuccess());
    toastrRedux(
      'Seller aprovado com sucesso. Todos os usuários estão ativos',
      'success',
    );
  } catch (error: unknown) {
    toastrRedux('Não foi possível aprovar o seller. Tente novamente.', 'error');
    yield put(approveSellerError());
  }
}
