/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IIntialState = {
  loading: boolean;
  errors: boolean;
  plans: IPayments.IPaymentPlan[];
  selectedPlan: IPayments.IPaymentPlan;
};

const initialState: IIntialState = {
  loading: false,
  errors: false,
  plans: [] as IPayments.IPaymentPlan[],
  selectedPlan: {} as IPayments.IPaymentPlan,
};

const adminPayments = createSlice({
  name: 'AdminPayments',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    createPaymentPlanRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createPaymentPlanSuccess: state => {
      state.loading = false;
    },
    createPaymentPlanError: state => {
      state.loading = false;
      state.errors = true;
    },
    getPaymentPlansRequest: state => {
      state.loading = true;
    },
    getPaymentPlansSuccess: (
      state,
      action: PayloadAction<IPayments.IPaymentPlan[]>,
    ) => {
      state.loading = false;
      state.plans = action.payload;
    },
    getPaymentPlansError: state => {
      state.loading = false;
      state.errors = true;
    },
    showPaymentPlanRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    showPaymentPlanSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.selectedPlan = action.payload;
    },
    showPaymentPlanError: state => {
      state.loading = false;
      state.errors = true;
    },
    updatePaymentPlanRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    updatePaymentPlanSuccess: state => {
      state.loading = false;
    },
    updatePaymentPlanError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  createPaymentPlanRequest,
  createPaymentPlanSuccess,
  createPaymentPlanError,
  getPaymentPlansRequest,
  getPaymentPlansSuccess,
  getPaymentPlansError,
  showPaymentPlanRequest,
  showPaymentPlanSuccess,
  showPaymentPlanError,
  updatePaymentPlanRequest,
  updatePaymentPlanSuccess,
  updatePaymentPlanError,
} = adminPayments.actions;

export default adminPayments.reducer;
