/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  getStatesSuccess,
  getStatesError,
  getCitiesSuccess,
  getCitiesError,
  getAddressSuccess,
  getAddressError,
} from '../Reducers/PublicData';

export function* getStatesSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/ibge/states');

    if (response.status !== 200)
      throw new Error('Ocorreu um erro ao carregar os dados.');

    const states = response.data.map((state: any) => ({
      label: state.nome,
      value: state.uf,
    }));

    yield put(getStatesSuccess(states));
  } catch (error: unknown) {
    toastrRedux('Ocorreu um erro ao carregar os dados.', 'error');
    yield put(getStatesError());
  }
}

export function* getCitiesSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/ibge/states/${payload}/cities`);

    if (response.status !== 200)
      throw new Error('Ocorreu um erro ao carregar os dados.');

    const cities = response.data.map((city: any) => ({
      label: city.nome,
      value: city.id,
    }));

    yield put(getCitiesSuccess(cities));
  } catch (error: unknown) {
    toastrRedux('Ocorreu um erro ao carregar os dados.', 'error');
    yield put(getCitiesError());
  }
}

export function* getAddressRequestSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/public/cep/${payload}`);

    if (response.status !== 200)
      throw new Error('Ocorreu um erro ao carregar os dados.');

    yield put(getAddressSuccess(response.data));
  } catch (error: unknown) {
    toastrRedux('Ocorreu um erro ao carregar os dados.', 'error');
    yield put(getAddressError());
  }
}
