import React, { createContext, useContext, useState, useMemo } from 'react';

type IProps = {
  children: React.ReactNode;
};

type LoadingContextData = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData,
);

const LoadingProvider: React.FC<IProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const values = useMemo(() => ({ isLoading, setIsLoading }), [isLoading]);

  return (
    <LoadingContext.Provider value={values}>{children}</LoadingContext.Provider>
  );
};

function useLoading(): LoadingContextData {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  return context;
}

export { LoadingProvider, useLoading };
