/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialState = {
  loading: boolean;
  errors: boolean;
  company: ICompany;
};

const initialState: IInitialState = {
  loading: false,
  errors: false,
  company: {} as ICompany,
};

const companies = createSlice({
  name: 'Companies',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getCompanyRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getCompanySuccess: (state, action: PayloadAction<ICompany>) => {
      state.loading = false;
      state.company = action.payload;
    },
    getCompanyError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getCompanyRequest,
  getCompanySuccess,
  getCompanyError,
} = companies.actions;

export default companies.reducer;
