import { takeLatest } from 'redux-saga/effects';

import {
  getPaymentLinkDataSaga,
  createTransactionRequestSaga,
  findConsumerRequestSaga,
} from './Payments';

export default [
  takeLatest('Payments/getPaymentDataRequest', getPaymentLinkDataSaga),
  takeLatest('Payments/createTransactionRequest', createTransactionRequestSaga),
  takeLatest('Payments/findConsumerRequest', findConsumerRequestSaga),
];
