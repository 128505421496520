import api from '@services/api';

export default class HelperFunctions {
  static async getStates(): Promise<IState[]> {
    const { data: loadedStates } = await api.get<IState[]>('/ibge/states');
    return loadedStates;
  }

  static async getCitiesByUF(uf: string): Promise<ICity[]> {
    const { data: loadedCities } = await api.get<ICity[]>(
      `/ibge/states/${uf}/cities`,
    );
    return loadedCities;
  }

  static getChangedValues<T>(newObject: IGenericType, object: T): IGenericType {
    const values = newObject;
    Object.keys(values).forEach(
      key => values[key] === object[key as keyof T] && delete values[key],
    );
    return values;
  }

  static cleanEmptyObjectValues<T>(object: T): T {
    const obj = Object.fromEntries(
      Object.entries(object).filter(([, value]) => {
        return value;
      }),
    );

    return obj as T;
  }

  static parseMoney(value: string): string {
    return new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(parseFloat(value));
  }
}
