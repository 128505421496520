import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import Helpers from '@helpers/Functions';

import {
  getUsersSuccess,
  getUsersError,
  deleteUserSuccess,
  deleteUserError,
  createUserSuccess,
  createUserError,
  userFormFactorySuccess,
  userFormFactoryError,
  updateUserSuccess,
  updateUserError,
} from '@store/Reducers/Admin/AdminUsers';

export function* getUsersRequestSaga({
  payload,
}: PayloadAction<{ paginate: any; filters: any }>): IRedux.ReduxCustom {
  const { paginate, filters } = payload;
  const parsedFilters = Helpers.cleanEmptyObjectValues(filters);

  try {
    const response = yield call(api.get, '/users', {
      params: { ...paginate, ...parsedFilters },
    });

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield put(getUsersSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getUsersError());
    }
  }
}

export function* createUserRequestSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  // createUserRequest
  try {
    const response = yield call(api.post, '/users', payload);
    if (response.status !== 201) {
      throw new Error(response.statusText);
    }
    toastrRedux('Usuário adicionado com sucesso.', 'success');
    yield put(createUserSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createUserError());
    }
  }
}

export function* updateUserRequestSaga({
  payload,
}: PayloadAction<{ userId: string; data: any }>): IRedux.ReduxCustom {
  try {
    const { userId, data } = payload;
    const response = yield call(api.patch, `/users/${userId}`, data);
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    toastrRedux('Usuário atualizado com sucesso.', 'success');
    yield put(updateUserSuccess());
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(updateUserError());
    }
  }
}

export function* deleteUserRequestSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.delete, `/users/${payload}`);
    if (response.status !== 204) {
      throw new Error(response.statusText);
    }
    toastrRedux('Usuário deletado com sucesso!', 'success');
    yield put(deleteUserSuccess(payload));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(deleteUserError());
    }
  }
}

export function* userFormFactoryRequestSaga({
  payload,
}: PayloadAction<{ userId?: string }>): IRedux.ReduxCustom {
  try {
    /**
     * Get roles
     */
    const roles = yield call(api.get, '/users/roles');
    if (roles.status !== 200) {
      throw new Error(roles.statusText);
    }
    const parsedRoles = roles.data.map((role: any) => {
      return { value: role.id, label: role.name };
    });
    /**
     * Get companies
     */
    const companies = yield call(api.get, '/companies');
    if (companies.status !== 200) {
      throw new Error(companies.statusText);
    }

    const parsedCompanies = companies.data.companies.map((company: any) => {
      return { value: company.id, label: company.company_name };
    });

    /**
     * Get user
     */
    if (!payload.userId) {
      yield put(
        userFormFactorySuccess({
          roles: parsedRoles,
          companies: parsedCompanies,
        }),
      );
    } else {
      const response = yield call(api.get, `/users/${payload.userId}`);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      yield put(
        userFormFactorySuccess({
          roles: parsedRoles,
          companies: parsedCompanies,
          user: response.data,
        }),
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(userFormFactoryError());
    }
  }
}
