/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialStateTyppe = {
  loading: boolean;
  errors: boolean;
  roles: IRole[];
  permissions: IPermission;
  showDeleteModal: boolean;
  selectedRole: number | undefined;
  role: IRole | undefined;
};

const initialState: IInitialStateTyppe = {
  roles: [] as IRole[],
  permissions: {} as IPermission,
  loading: false,
  errors: false,
  showDeleteModal: false,
  selectedRole: undefined,
  role: undefined,
};

const adminRoles = createSlice({
  name: 'AdminRoles',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getRolesRequest: state => {
      state.loading = true;
    },
    getRolesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.roles = action.payload;
    },
    getRolesError: state => {
      state.loading = false;
      state.errors = true;
    },
    getPermissionsRequest: state => {
      state.loading = true;
    },
    getPermissionsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissions = action.payload;
    },
    getPermissionsError: state => {
      state.loading = false;
      state.errors = true;
    },
    createRoleRequest: (state, action: PayloadAction<{ role: any }>) => {
      state.loading = true;
    },
    createRoleSuccess: state => {
      state.loading = false;
    },
    createRoleError: state => {
      state.loading = false;
      state.errors = true;
    },
    showDeleteRoleModal: (state, action: PayloadAction<{ id: number }>) => {
      state.showDeleteModal = true;
      state.selectedRole = action.payload.id;
    },
    hideDeleteRoleModal: state => {
      state.showDeleteModal = false;
      state.selectedRole = undefined;
    },
    deleteRoleRequest: (
      state,
      action: PayloadAction<{ id: number | undefined }>,
    ) => {
      state.loading = true;
      state.showDeleteModal = false;
    },
    deleteRoleSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.showDeleteModal = false;
      state.selectedRole = undefined;
      state.roles = state.roles.filter(role => role.id !== action.payload);
    },
    deleteRoleError: state => {
      state.loading = false;
      state.errors = true;
      state.showDeleteModal = false;
      state.selectedRole = undefined;
    },
    showRoleRequest: (state, action: PayloadAction<{ id: number }>) => {
      state.loading = true;
    },
    showRoleSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.role = {
        ...action.payload.role,
        permissions: action.payload.permissions,
      };
    },
    showRoleError: state => {
      state.loading = false;
      state.errors = true;
    },
    updateRoleRequest: (state, action: PayloadAction<{ role: any }>) => {
      state.loading = true;
    },
    updateRoleSuccess: state => {
      state.loading = false;
    },
    updateRoleError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getRolesRequest,
  getRolesSuccess,
  getRolesError,
  getPermissionsRequest,
  getPermissionsSuccess,
  getPermissionsError,
  createRoleRequest,
  createRoleSuccess,
  createRoleError,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleError,
  showDeleteRoleModal,
  hideDeleteRoleModal,
  showRoleRequest,
  showRoleSuccess,
  showRoleError,
  updateRoleRequest,
  updateRoleSuccess,
  updateRoleError,
} = adminRoles.actions;

export default adminRoles.reducer;
