import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { toastrRedux } from '@hooks/useToast';
import history from '@hooks/useHistory';

import api from '@services/api';

import {
  findCompanyByTaxNumberSuccess,
  findCompanyByTaxNumberFail,
  getPartnerCitiesSuccess,
  getPartnerCitiesError,
  getPartnerAddressSuccess,
  getPartnerAddressError,
  createNewSellerSuccess,
  createNewSellerError,
} from '@store/Reducers/SellerOnboarding';

export function* getCompanyPublicDataSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/public/cnpj/${payload}`);
    if (response.status !== 200)
      throw new Error('CNPJ não encontrado na base de dados.');
    yield put(findCompanyByTaxNumberSuccess(response.data));
  } catch (error: unknown) {
    toastrRedux('Ocorreu um erro ao buscar pelo CNPJ informado', 'error');
    yield put(findCompanyByTaxNumberFail());
  }
}

export function* getPartnerCitiesSaga({
  payload,
}: PayloadAction<{ uf: string; index: number }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/ibge/states/${payload.uf}/cities`);

    if (response.status !== 200)
      throw new Error('Não foi possível carregar os dados.');

    const cities = response.data.map((city: any) => ({
      label: city.nome,
      value: city.id,
    }));

    yield put(getPartnerCitiesSuccess({ data: cities, index: payload.index }));
  } catch (error: unknown) {
    toastrRedux('Não foi possível carregar os dados.', 'error');
    yield put(getPartnerCitiesError());
  }
}

export function* getPartnerAddressRequestSaga({
  payload,
}: PayloadAction<{ zipCode: string; index: number }>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/public/cep/${payload.zipCode}`);
    if (response.status !== 200) throw new Error();
    yield put(
      getPartnerAddressSuccess({ data: response.data, index: payload.index }),
    );
  } catch (error) {
    toastrRedux('Não foi possível carregar os dados.', 'error');
    yield put(getPartnerAddressError());
  }
}

export function* createNewSellerSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/sellers', payload);
    if (response.status !== 201) throw new Error();
    yield put(createNewSellerSuccess());
    history.push(`/onboarding/${response.data.id}/success`);
  } catch (error: unknown) {
    toastrRedux('Não foi possível criar a sua conta.', 'error');
    yield put(createNewSellerError());
  }
}
