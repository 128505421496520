/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type INITIAL_STATE = {
  loading: boolean;
  errors: boolean;
  user: IUser | undefined;
  users: IUser[];
  totalUsers: number;
  roles: Array<any>;
  companies: Array<any>;
};

const INITIAL_STATE: INITIAL_STATE = {
  loading: false,
  errors: false,
  user: undefined,
  users: [] as IUser[],
  totalUsers: 0,
  roles: [] as Array<any>,
  companies: [] as Array<any>,
};

const adminUsers = createSlice({
  name: 'AdminUsers',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    getUsersRequest: (
      state,
      action: PayloadAction<{ paginate?: any; filters?: any }>,
    ) => {
      state.loading = true;
    },
    getUsersSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.users = action.payload.users;
      state.totalUsers = action.payload.total;
    },
    getUsersError: state => {
      state.loading = false;
      state.errors = true;
    },
    createUserRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createUserSuccess: (state, action: PayloadAction<IUser>) => {
      state.loading = false;
      state.users = [action.payload, ...state.users];
      state.totalUsers += 1;
    },
    createUserError: state => {
      state.loading = false;
      state.errors = true;
    },
    updateUserRequest: (
      state,
      action: PayloadAction<{ userId: string; data: any }>,
    ) => {
      state.loading = true;
    },
    updateUserSuccess: state => {
      state.loading = false;
    },
    updateUserError: state => {
      state.loading = false;
      state.errors = true;
    },
    deleteUserRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    deleteUserSuccess: (state, action: PayloadAction<string>) => {
      state.users = state.users.filter(user => user.id !== action.payload);
      state.totalUsers -= 1;
      state.loading = false;
    },
    deleteUserError: state => {
      state.loading = false;
      state.errors = true;
    },
    userFormFactoryRequest: (
      state,
      action: PayloadAction<{ userId?: string }>,
    ) => {
      state.loading = true;
    },
    userFormFactorySuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.roles = action.payload.roles;
      state.companies = action.payload.companies;
      state.user = action.payload.user;
    },
    userFormFactoryError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getUsersRequest,
  getUsersSuccess,
  getUsersError,
  createUserRequest,
  createUserSuccess,
  createUserError,
  updateUserRequest,
  updateUserSuccess,
  updateUserError,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserError,
  userFormFactoryRequest,
  userFormFactorySuccess,
  userFormFactoryError,
} = adminUsers.actions;

export default adminUsers.reducer;
