import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import GlobalStyle from './styles/global';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-day-picker/dist/style.css';

import LoadingState from './components/LoadingState';

import AppProvider from './hooks';
import history from './hooks/useHistory';

const Routes = React.lazy(() => import('./routes'));

const App: React.FC = () => (
  <HistoryRouter history={history}>
    <AppProvider>
      <React.Suspense fallback={<LoadingState />}>
        <Routes />
      </React.Suspense>
      <ToastContainer />
      <GlobalStyle />
    </AppProvider>
  </HistoryRouter>
);

export default App;
