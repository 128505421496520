import React, { useContext, createContext, useCallback, useMemo } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import { PRIMARY_COLORS } from '../styles/global';

type IProps = {
  children: React.ReactNode;
};

type ToastContent = {
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error' | 'default';
};

type ToastContextData = {
  addToast(data: ToastContent): void;
};

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

const ToastProvider: React.FC<IProps> = ({ children }) => {
  const addToast = useCallback(
    ({ message, type = 'default' }: ToastContent) => {
      toast(message, {
        type,
        position: toast.POSITION.TOP_RIGHT,
        draggable: true,
        pauseOnHover: true,
        closeButton: true,
        progressStyle: {
          background: PRIMARY_COLORS.primary,
        },
      });
    },
    [],
  );

  const values = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={values}>{children}</ToastContext.Provider>
  );
};

function useToast(): ToastContextData {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast mubt be used with a ToastProvider');
  }

  return context;
}

function toastrRedux(message: string, type: TypeOptions): void {
  toast(message, {
    type,
    position: toast.POSITION.TOP_RIGHT,
    draggable: true,
    pauseOnHover: true,
    closeButton: true,
    progressStyle: {
      background: PRIMARY_COLORS.primary,
    },
  });
}

export { useToast, ToastProvider, toastrRedux };
