import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import history from '@hooks/useHistory';

import {
  transferRequestError,
  transferRequestSuccess,
} from '@store/Reducers/Sellers/Transfers';

export function* transferRequestSaga({
  payload,
}: PayloadAction): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/transfers', payload);
    if (response.status === 201) {
      toastrRedux('Transferência realizada com sucesso.', 'success');
      yield put(transferRequestSuccess());
      history.push('/sellers/wallet');
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(transferRequestError());
    }
  }
}
