/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  loading: boolean;
  errors: boolean;
  consumers: IConsumer[];
  totalConsumers: number;
  consumer: IConsumer | undefined;
};

const INITIAL_STATE: InitialState = {
  loading: false,
  errors: false,
  consumers: [] as IConsumer[],
  totalConsumers: 0,
  consumer: undefined,
};

const adminConsumers = createSlice({
  name: 'AdminConsumers',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    getConsumersRequest: (
      state,
      action: PayloadAction<{ paginate?: any; filters?: any }>,
    ) => {
      state.loading = true;
    },
    getConsumersSuccess: (
      state,
      action: PayloadAction<{ consumers: any; total: number }>,
    ) => {
      state.loading = false;
      state.consumers = action.payload.consumers;
      state.totalConsumers = action.payload.total;
    },
    getConsumersErrors: state => {
      state.loading = false;
      state.errors = true;
    },
    getConsumerRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getConsumerSuccess: (state, action: PayloadAction<IConsumer>) => {
      state.loading = false;
      state.consumer = action.payload;
    },
    getConsumerError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getConsumersRequest,
  getConsumersSuccess,
  getConsumersErrors,
  getConsumerRequest,
  getConsumerSuccess,
  getConsumerError,
} = adminConsumers.actions;

export default adminConsumers.reducer;
