/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  findedCompany: {} as any,
  partners: {
    addresses: [] as Array<any>,
    cities: [] as Array<Array<IGenericType>>,
  },
};

const SellerOnboarding = createSlice({
  name: 'SellerOnboarding',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    findCompanyByTaxNumber: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    findCompanyByTaxNumberSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.findedCompany = action.payload;
    },
    findCompanyByTaxNumberFail: state => {
      state.loading = false;
    },
    getPartnerAddressRequest: (
      state,
      action: PayloadAction<{ zipCode: string; index: number }>,
    ) => {
      state.loading = true;
    },
    getPartnerAddressSuccess: (
      state,
      action: PayloadAction<{ data: any; index: number }>,
    ) => {
      state.loading = false;
      state.partners.addresses[action.payload.index] = action.payload.data;
    },
    getPartnerAddressError: state => {
      state.loading = false;
    },
    getPartnerCitiesRequest: (
      state,
      action: PayloadAction<{ uf: string; index: number }>,
    ) => {
      state.loading = true;
      state.partners.cities[action.payload.index] = [];
    },
    getPartnerCitiesSuccess: (
      state,
      action: PayloadAction<{ data: Array<IGenericType>; index: number }>,
    ) => {
      state.loading = false;
      state.partners.cities[action.payload.index] = action.payload.data;
    },
    getPartnerCitiesError: state => {
      state.loading = false;
    },
    createNewSellerRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createNewSellerSuccess: state => {
      state.loading = false;
    },
    createNewSellerError: state => {
      state.loading = false;
    },
  },
});

export const {
  clearState,
  findCompanyByTaxNumber,
  findCompanyByTaxNumberFail,
  findCompanyByTaxNumberSuccess,
  getPartnerCitiesRequest,
  getPartnerCitiesSuccess,
  getPartnerCitiesError,
  getPartnerAddressRequest,
  getPartnerAddressSuccess,
  getPartnerAddressError,
  createNewSellerRequest,
  createNewSellerSuccess,
  createNewSellerError,
} = SellerOnboarding.actions;

export default SellerOnboarding.reducer;
