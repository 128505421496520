import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  getCompanySuccess,
  getCompanyError,
} from '@store/Reducers/Sellers/Companies';

export function* getCompanyRequestSaga({
  payload,
}: PayloadAction): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/companies/${payload}`);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    yield put(getCompanySuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getCompanyError());
    }
  }
}
