import { all, takeLatest, AllEffect, ForkEffect } from 'redux-saga/effects';

import AdminSagas from './Admin';
import SellersSagas from './Sellers';
import PaymentsSagas from './Payments';

import {
  getStatesSaga as getPublicStatesSaga,
  getCitiesSaga as getPublicCitiesSaga,
  getAddressRequestSaga,
} from './PublicData';

import { userLoginRequestSaga, updateProfileRequestSaga } from './Auth';

import {
  getCompanyPublicDataSaga,
  getPartnerCitiesSaga,
  getPartnerAddressRequestSaga,
  createNewSellerSaga,
} from './SellerOnboarding';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<never>>,
  void,
  unknown
> {
  yield all([
    ...AdminSagas,
    ...SellersSagas,
    ...PaymentsSagas,
    /**
     * Public data sagas
     */
    takeLatest('PublicData/getStatesRequest', getPublicStatesSaga),
    takeLatest('PublicData/getCitiesRequest', getPublicCitiesSaga),
    takeLatest('PublicData/getAddressRequest', getAddressRequestSaga),
    /**
     * Auth sagas
     */
    takeLatest('Auth/loginRequest', userLoginRequestSaga),
    takeLatest('Auth/updateProfileRequest', updateProfileRequestSaga),
    /**
     * Seller onboarding sagas
     */
    takeLatest(
      'SellerOnboarding/findCompanyByTaxNumber',
      getCompanyPublicDataSaga,
    ),
    takeLatest(
      'SellerOnboarding/getPartnerCitiesRequest',
      getPartnerCitiesSaga,
    ),
    takeLatest(
      'SellerOnboarding/getPartnerAddressRequest',
      getPartnerAddressRequestSaga,
    ),
    takeLatest('SellerOnboarding/createNewSellerRequest', createNewSellerSaga),
  ]);
}
