/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialState = {
  loading: boolean;
  errors: boolean;
  balance: {
    unavailable: number;
    future: number;
    current: number;
  };
};

const initialState: IInitialState = {
  loading: false,
  errors: false,
  balance: {
    unavailable: 0,
    future: 0,
    current: 0,
  },
};

const balance = createSlice({
  name: 'SellerBalance',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getBalanceRequest: state => {
      state.loading = true;
    },
    getBalanceSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.balance = action.payload;
    },
    getBalanceError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getBalanceRequest,
  getBalanceSuccess,
  getBalanceError,
} = balance.actions;

export default balance.reducer;
