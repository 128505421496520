import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import HelperFunctions from '@helpers/Functions';

import {
  getOrdersSuccess,
  getOrdersError,
} from '@store/Reducers/Admin/AdminOrders';

export function* getOrdersRequestSaga({
  payload,
}: PayloadAction<{ paginate: any; filters: any }>): IRedux.ReduxCustom {
  const parsedFilters = HelperFunctions.cleanEmptyObjectValues(payload.filters);
  try {
    const response = yield call(api.get, '/payments/orders', {
      params: { ...payload.paginate, ...parsedFilters },
    });
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    yield put(getOrdersSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getOrdersError());
    }
  }
}
