/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BooleanLocale } from 'yup/lib/locale';

export type IAuthState = {
  user: IUser;
  token: string;
};

type IInitialStateType = {
  loading: boolean;
  errors: boolean;
  data: IAuthState;
};

const INITIAL_STATE: IInitialStateType = {
  loading: false,
  errors: false,
  data: {} as IAuthState,
};

const auth = createSlice({
  name: 'Auth',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    loginRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    loginError: state => {
      state.loading = false;
    },
    logoutRequest: () => {
      return INITIAL_STATE;
    },
    setData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    updateProfileRequest: (
      state,
      action: PayloadAction<{ data: any; profileId: string }>,
    ) => {
      state.loading = true;
    },
    updateProfileSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data.user = action.payload;
    },
    updateProfileError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
  setData,
  clearState,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,
} = auth.actions;

export default auth.reducer;
