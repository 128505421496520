import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
*{
  margin: 0;
  border: 0ch;
  box-sizing: border-box;
  outline: 0;
}
body{
  -webkit-font-smoothing: antialiased;
}
body, input, button{
  font-size: 16px;
}
h1, h2, h3, h4, h5, h6, strong{
  font-weight: 500;
}
button{
  cursor: pointer;
}
a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}
`;

export const PRIMARY_COLORS = {
  whiteText: '#ffffff',
  primary: '#7dc242',
  light: '#bdeb96',
  medium: '#66aa2c',
  dark: '#295406',
  darkYellow: '#c79100',
  primaryYellow: '#ffc107',
  lightYellow: '#fff350',
  darkRed: '#870000',
  primaryRed: '#bf360c',
  lightRed: '#f9683a',
};
