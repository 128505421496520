import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Container } from './styles';

const LoadingState: React.FC = () => {
  return (
    <Container>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Container>
  );
};

export default LoadingState;
