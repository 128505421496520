/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  loading: false,
  errors: false,
  orders: [] as IPayments.IOrder[],
  totalOrders: 0,
  order: {},
};

const adminOrders = createSlice({
  name: 'AdminOrders',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    getOrdersRequest: (
      state,
      action: PayloadAction<{ paginate: any; filters: any }>,
    ) => {
      state.loading = true;
    },
    getOrdersSuccess: (
      state,
      action: PayloadAction<{ orders: any; total: number }>,
    ) => {
      state.loading = false;
      state.totalOrders = action.payload.total;
      state.orders = action.payload.orders;
    },
    getOrdersError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersError,
} = adminOrders.actions;

export default adminOrders.reducer;
