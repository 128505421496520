/* eslint-disable import/no-duplicates */
/* eslint-disable no-param-reassign */
import { call, put } from 'redux-saga/effects';
// import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import {
  getTotalSellersSuccess,
  getTotalSellersError,
  getTotalUsersSuccess,
  getTotalUsersError,
  getTransactionsStatsSuccess,
  getTransactionsStatsError,
  getTransactionsHistorySuccess,
  getTransactionsHistoryError,
  getSellersRankingSuccess,
  getTotalSellersRankingError,
} from '@store/Reducers/Admin/AdminDashboard';

const errorMessage = 'Não foi possível carregar alguns dados do dashboard.';

export function* getTotalUsersRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/users/dashboard');
    if (response.status !== 200) {
      throw new Error(errorMessage);
    }
    yield put(getTotalUsersSuccess(response.data.users));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getTotalUsersError());
    }
  }
}

export function* getTotalSellersRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/sellers/dashboard');
    if (response.status !== 200) {
      throw new Error(errorMessage);
    }
    yield put(getTotalSellersSuccess(response.data.sellers));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getTotalSellersError());
    }
  }
}

export function* getTransactionsStatsRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/payments/transactions/dashboard');
    if (response.status !== 200) {
      throw new Error(errorMessage);
    }
    yield put(getTransactionsStatsSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getTransactionsStatsError());
    }
  }
}

export function* getTransactionsHistory(): IRedux.ReduxCustom {
  try {
    // eslint-disable-next-line prefer-const
    let response = yield call(
      api.get,
      'payments/transactions/dashboard/history',
    );
    if (response.status !== 200) {
      throw new Error(errorMessage);
    }
    Object.values<any>(response.data).forEach(period => {
      period.month = format(new Date(period.month), 'MMM', { locale: ptBR });
      period.total /= 100;
    });
    yield put(getTransactionsHistorySuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getTransactionsHistoryError());
    }
  }
}

export function* getSellersRankingSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/sellers/dashboard/ranking');
    if (response.status !== 200) {
      throw new Error(errorMessage);
    }
    yield put(getSellersRankingSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getTotalSellersRankingError());
    }
  }
}
