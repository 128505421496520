/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IAddressData = {
  street: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
};

type IInitialState = {
  companies: ICompany[];
  totalCompanies: number;
  loading: boolean;
  errors: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  states: IGenericType[];
  roles: IGenericType[];
  company: {
    cities: IGenericType[];
    data: any;
    address: IAddressData;
  };
  partners: {
    cities: Array<Array<IGenericType>>;
    address: Array<IAddressData>;
  };
};

const initialState: IInitialState = {
  companies: [] as ICompany[],
  totalCompanies: 0,
  loading: false,
  errors: false,
  showEditModal: false,
  showDeleteModal: false,
  states: [],
  roles: [],
  company: {
    cities: [],
    data: {},
    address: {} as IAddressData,
  },
  partners: {
    cities: [],
    address: [],
  },
};

const adminCompanies = createSlice({
  name: 'AdminCompanies',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getCompaniesRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getCompaniesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.companies = action.payload.companies;
      state.totalCompanies = action.payload.total;
    },
    getCompaniesError: state => {
      state.loading = false;
      state.errors = true;
    },
    handleEditModal: (state, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    handleDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteModal = action.payload;
    },
    getStatesRequest: state => {
      state.loading = true;
    },
    getStatesSuccess: (state, action: PayloadAction<IGenericType[]>) => {
      state.loading = false;
      state.states = action.payload;
    },
    getStatesError: state => {
      state.loading = false;
      state.errors = true;
    },
    getCitiesRequest: (
      state,
      action: PayloadAction<{ uf: string; userIndex?: number }>,
    ) => {
      state.loading = true;
    },
    getCitiesSuccess: (state, action: PayloadAction<IGenericType[]>) => {
      state.loading = false;
      state.company.cities = action.payload;
    },
    getPartnersCitiesSuccess: (
      state,
      action: PayloadAction<{ cities: IGenericType[]; userIndex: number }>,
    ) => {
      state.loading = false;
      state.partners.cities[action.payload.userIndex] = action.payload.cities;
    },
    getCitiesError: state => {
      state.loading = false;
      state.errors = true;
    },
    getCompanyDataRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getCompanyDataSuccess: (state, action) => {
      state.loading = false;
      state.company.data = action.payload;
    },
    getCompanyDataError: state => {
      state.loading = false;
      state.errors = true;
    },
    getAddressRequest: (
      state,
      action: PayloadAction<{ cep: string; userIndex?: number }>,
    ) => {
      state.loading = true;
    },
    getAddressSuccess: (state, action: PayloadAction<IAddressData>) => {
      state.loading = false;
      state.company.address = action.payload;
    },
    getPartnerAddressSuccess: (
      state,
      action: PayloadAction<{ address: IAddressData; userIndex: number }>,
    ) => {
      state.loading = false;
      state.partners.address[action.payload.userIndex] = action.payload.address;
    },
    getAddressError: state => {
      state.loading = false;
      state.errors = true;
    },
    getRolesRequest: state => {
      state.loading = true;
    },
    getRolesSuccess: (state, action: PayloadAction<IGenericType[]>) => {
      state.loading = false;
      state.roles = action.payload;
    },
    getRolesError: state => {
      state.loading = false;
      state.errors = true;
    },
    createSellerRequest: (state, action: PayloadAction<{ data: Object }>) => {
      state.loading = true;
    },
    createSellerSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
    },
    createSellerError: state => {
      state.loading = false;
      state.errors = true;
    },
    showCompanyRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    showCompanySuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.company.data = action.payload;
    },
    showCompanyError: state => {
      state.loading = false;
      state.errors = true;
    },
    updateCompanyRequest: (
      state,
      action: PayloadAction<{ companyId: string; data: any }>,
    ) => {
      state.loading = true;
    },
    updateCompanySuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.company.data = action.payload;
    },
    updateCompanyError: state => {
      state.loading = false;
      state.errors = true;
    },
    approveSellerRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    approveSellerSuccess: state => {
      state.loading = false;
      state.company.data.seller.is_active = true;
    },
    approveSellerError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesError,
  handleEditModal,
  handleDeleteModal,
  getStatesRequest,
  getStatesSuccess,
  getStatesError,
  getCitiesRequest,
  getCitiesSuccess,
  getPartnersCitiesSuccess,
  getCitiesError,
  getCompanyDataRequest,
  getCompanyDataSuccess,
  getCompanyDataError,
  getAddressRequest,
  getAddressSuccess,
  getAddressError,
  getPartnerAddressSuccess,
  getRolesRequest,
  getRolesError,
  getRolesSuccess,
  createSellerRequest,
  createSellerSuccess,
  createSellerError,
  showCompanyRequest,
  showCompanySuccess,
  showCompanyError,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyError,
  approveSellerRequest,
  approveSellerSuccess,
  approveSellerError,
} = adminCompanies.actions;

export default adminCompanies.reducer;
