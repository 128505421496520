import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

type IProps = {
  children: React.ReactNode;
};

type PageTitleContextType = {
  setPageTitle: (pageTitle: string) => void;
};

const PageTitleContext = createContext<PageTitleContextType>(
  {} as PageTitleContextType,
);

const PageTitleProvider: React.FC<IProps> = ({ children }) => {
  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    if (pageTitle) {
      document.title = `${pageTitle} - BateClick Pay`;
    }
  }, [pageTitle]);

  const values = useMemo(() => ({ setPageTitle }), []);

  return (
    <PageTitleContext.Provider value={values}>
      {children}
    </PageTitleContext.Provider>
  );
};

function usePageTitle(): PageTitleContextType {
  const context = useContext(PageTitleContext);

  if (!context) {
    throw new Error('usePageTitle must be used within a PageTitleProvider');
  }

  return context;
}

export { usePageTitle, PageTitleProvider };
