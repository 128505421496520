import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import Helpers from '@helpers/Functions';
import history from '@hooks/useHistory';

import {
  createPaymentLinksError,
  createPaymentLinksSuccess,
  getPaymentLinkSuccess,
  getPaymentLinkError,
  getPaymentLinksSuccess,
  getPaymentLinksError,
  deletePaymentLinkSuccess,
  deletePaymentLinkError,
} from '@store/Reducers/Sellers/PaymentLinks';

const createPaymentLinkRequest = (data: any) =>
  api.post('/payments/links', data);
export function* createPaymentLinkSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(createPaymentLinkRequest, payload);
    if (response.data) {
      yield put(createPaymentLinksSuccess());
      toastrRedux('Link de pagamento criado com sucesso.', 'success');
      history.push(
        `/sellers/transactions/payment-links/detail/${response.data.id}?msg=success`,
      );
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createPaymentLinksError());
    }
  }
}

export function* getPaymentLinkSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/payments/links/${payload}`);
    if (response.data) {
      yield put(getPaymentLinkSuccess(response.data));
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getPaymentLinkError());
    }
  }
}

export function* listAllPaymentLinksSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const { pagination, filters } = payload;
    const parsedFilters = Helpers.cleanEmptyObjectValues(filters);

    const response = yield call(api.get, '/payments/links', {
      params: { ...pagination, ...parsedFilters },
    });
    if (response.data) {
      yield put(getPaymentLinksSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getPaymentLinksError());
    }
  }
}

export function* deletePaymentLinkSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom<any, any> {
  try {
    const response = yield call(api.delete, `/payments/links/${payload}`);
    if (response.status !== 204) {
      throw new Error(response.statusText);
    }
    yield put(deletePaymentLinkSuccess());
    toastrRedux('Link de pagamento excluído com sucesso.', 'success');
    history.replace('/sellers/transactions/payment-links');
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(deletePaymentLinkError());
    }
  }
}
