import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  loginSuccess,
  loginError,
  updateProfileSuccess,
  updateProfileError,
} from '@store/Reducers/Auth';

export function* userLoginRequestSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/sessions', payload);
    if (response.status === 200) {
      yield put(loginSuccess(response.data));
      toastrRedux('Login realizado com sucesso!', 'success');

      const { token, user } = response.data;

      api.defaults.headers.common.authorization = `Bearer ${token}`;

      window.localStorage.setItem('@BateClickPay:user', JSON.stringify(user));
      window.localStorage.setItem('@BateClickPay:token', token);
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(loginError());
    }
  }
}

export function* updateProfileRequestSaga({
  payload,
}: PayloadAction<{ data: any; profileId: string }>): IRedux.ReduxCustom {
  const { profileId, data } = payload;
  try {
    const response = yield call(api.patch, `/users/profile/${profileId}`, data);
    if (response.status === 200) {
      const userData: any = window.localStorage.getItem('@BateClickPay:user');
      const { role } = JSON.parse(userData);
      const updatedUser = response.data;
      Object.assign(updatedUser.role, role);
      yield put(updateProfileSuccess(updatedUser));
      toastrRedux('Perfil atualizado com sucesso!', 'success');
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(updateProfileError());
    }
  }
}
