import { call, put } from 'redux-saga/effects';
// import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  getOrdersSuccess,
  getOrdersError,
  getOrderSuccess,
  getOrderError,
} from '@store/Reducers/Sellers/Transactions';
import { PayloadAction } from '@reduxjs/toolkit';
import Helpers from '@helpers/Functions';

export function* getOrdersRequestSaga({
  payload,
}: PayloadAction): IRedux.ReduxCustom {
  try {
    const { pagination, filters } = payload as any;
    const parsedFilters = Helpers.cleanEmptyObjectValues(filters);

    const response = yield call(api.get, '/payments/orders', {
      params: {
        ...pagination,
        ...parsedFilters,
      },
    });
    if (response.data) {
      yield put(getOrdersSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getOrdersError());
    }
  }
}

export function* getOrderRequestSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/payments/orders/${payload}`);
    if (response.status !== 200) {
      throw new Error();
    }
    yield put(getOrderSuccess(response.data));
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getOrderError());
    }
  }
}
