import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../services/api';
import { useToast } from './useToast';
import { useAuth } from './auth';

type IProps = {
  children: React.ReactNode;
};

type GatewayContextData = {
  message: string;
};

const GatewayContext = createContext<GatewayContextData>(
  {} as GatewayContextData,
);

const GatewayProvider: React.FC<IProps> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { addToast } = useToast();
  const { updateUser, user } = useAuth();

  const handleUpdateUser = useCallback(async () => {
    try {
      const response = await api.get(`/users/${user.id}`);
      updateUser(response.data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        addToast({
          type: 'error',
          message: error.message,
        });
      }
    }
  }, [updateUser, addToast, user]);

  useEffect(() => {
    if (searchParams.get('gatewayStatus') === 'authorized') {
      handleUpdateUser();
      addToast({
        type: 'success',
        message: 'Gateway de pagamento autorizado com sucesso.',
      });
    }
  }, [searchParams, addToast, handleUpdateUser]);

  const values = useMemo(() => ({ message: 'Hello World' }), []);

  return (
    <GatewayContext.Provider value={values}>{children}</GatewayContext.Provider>
  );
};

function usePaymentGateway(): GatewayContextData {
  const context = useContext(GatewayContext);
  if (!context) {
    throw new Error('usePaymentGateway must be used within a GatewayProvider');
  }

  return context;
}

export { usePaymentGateway, GatewayProvider };
