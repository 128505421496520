/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialState = {
  loading: boolean;
  errors: boolean;
  paymentData: IPayments.IPaymentLink | undefined;
  page: number;
  consumer: IConsumer | undefined;
  loadingConsumer: boolean;
};

const INITIAL_STATE: IInitialState = {
  loading: false,
  errors: false,
  paymentData: undefined,
  page: 0,
  loadingConsumer: false,
  consumer: undefined,
};

const payments = createSlice({
  name: 'Payments',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    nextPage: state => {
      state.page += 1;
    },
    backPage: state => {
      if (state.page > 0) {
        state.page -= 1;
      }
    },
    getPaymentDataRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getPaymentDataSuccess: (
      state,
      action: PayloadAction<IPayments.IPaymentLink>,
    ) => {
      state.loading = false;
      state.paymentData = action.payload;
    },
    getPaymentDataError: state => {
      state.loading = false;
      state.errors = true;
    },
    createTransactionRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createTransactionSuccess: state => {
      state.loading = false;
    },
    createTransactionError: state => {
      state.loading = false;
      state.errors = true;
    },
    findConsumerRequest: (
      state,
      action: PayloadAction<{ tax_number: string }>,
    ) => {
      state.loadingConsumer = true;
    },
    findConsumerSuccess: (state, action: PayloadAction<IConsumer>) => {
      state.consumer = action.payload;
      state.loadingConsumer = false;
    },
    findConsumerError: state => {
      state.loadingConsumer = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  getPaymentDataError,
  getPaymentDataRequest,
  getPaymentDataSuccess,
  nextPage,
  backPage,
  createTransactionRequest,
  createTransactionSuccess,
  createTransactionError,
  findConsumerRequest,
  findConsumerSuccess,
  findConsumerError,
} = payments.actions;
export default payments.reducer;
