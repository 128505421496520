import React from 'react';

import { ToastProvider } from './useToast';
import { AuthProvider } from './auth';
import { LoadingProvider } from './loading';
import { PageTitleProvider } from './usePageTitle';
import { GatewayProvider } from './gateway';

type IProps = {
  children: React.ReactNode;
};

const AppProvider: React.FC<IProps> = ({ children }) => {
  return (
    <PageTitleProvider>
      <ToastProvider>
        <AuthProvider>
          <GatewayProvider>
            <LoadingProvider>{children}</LoadingProvider>
          </GatewayProvider>
        </AuthProvider>
      </ToastProvider>
    </PageTitleProvider>
  );
};

export default AppProvider;
