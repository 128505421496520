import { combineReducers } from '@reduxjs/toolkit';

import Admin from './Admin';
import Sellers from './Sellers';
import Payments from './Payments';

import PublicData from './PublicData';
import Basic from './Basic';
import Auth from './Auth';
import SellerOnboarding from './SellerOnboarding';

export default combineReducers({
  ...Admin,
  ...Sellers,
  ...Payments,
  Basic,
  PublicData,
  Auth,
  SellerOnboarding,
});
