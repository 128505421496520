import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';
import history from '@hooks/useHistory';

import {
  getBankAccountsSuccess,
  getBankAccountsError,
  createBankAccountSuccess,
  createBankAccountError,
  getBankAccountSuccess,
  getBankAccountError,
  deleteBankAccountSuccess,
  deleteBankAccountError,
} from '@store/Reducers/Sellers/Wallet';

export function* getBankAccountsRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/payments/bank-account');
    if (response.status === 200) {
      yield put(getBankAccountsSuccess(response.data));
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getBankAccountsError());
    }
  }
}

export function* createBankAccountSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/payments/bank-account', payload);
    if (response.status === 201) {
      yield put(createBankAccountSuccess());
      toastrRedux('Conta bancária adicionada com sucesso!', 'success');
      history.push(
        `/sellers/wallet/bank-accounts/detail/${response.data.id}?msg=success`,
      );
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createBankAccountError());
    }
  }
}

export function* getBankAccountSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/payments/bank-account/${payload}`);
    if (response.status === 200) {
      yield put(getBankAccountSuccess(response.data));
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getBankAccountError());
    }
  }
}

export function* deleteBankAccountSaga({
  payload,
}: PayloadAction<string>): IRedux.ReduxCustom {
  try {
    const response = yield call(
      api.delete,
      `/payments/bank-account/${payload}`,
    );
    if (response.status === 204) {
      yield put(deleteBankAccountSuccess());
      toastrRedux('Conta bancária removida com sucesso!', 'success');
      history.replace('/sellers/wallet');
    }
  } catch (error) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(deleteBankAccountError());
    }
  }
}
