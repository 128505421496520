import { takeLatest } from 'redux-saga/effects';

import {
  createPaymentLinkSaga,
  getPaymentLinkSaga,
  listAllPaymentLinksSaga,
  deletePaymentLinkSaga,
} from './PaymentLinks';

import { getOrdersRequestSaga, getOrderRequestSaga } from './Transactions';

import { getBalanceRequestSaga } from './Balance';

import {
  getBankAccountsRequestSaga,
  createBankAccountSaga,
  getBankAccountSaga,
  deleteBankAccountSaga,
} from './Wallet';

import { transferRequestSaga } from './Transfers';

import { getCompanyRequestSaga } from './Companies';

export default [
  // Payment Links
  takeLatest('PaymentLinks/createPaymentLinksRequest', createPaymentLinkSaga),
  takeLatest('PaymentLinks/getPaymentLinkRequest', getPaymentLinkSaga),
  takeLatest('PaymentLinks/getPaymentLinksRequest', listAllPaymentLinksSaga),
  takeLatest('PaymentLinks/deletePaymentLinkRequest', deletePaymentLinkSaga),

  // Transactions
  takeLatest('Transactions/getOrdersRequest', getOrdersRequestSaga),
  takeLatest('Transactions/getOrderRequest', getOrderRequestSaga),

  // Balance
  takeLatest('SellerBalance/getBalanceRequest', getBalanceRequestSaga),

  // Wallet
  takeLatest('SellerWallet/getBankAccountsRequest', getBankAccountsRequestSaga),
  takeLatest('SellerWallet/createBankAccountRequest', createBankAccountSaga),
  takeLatest('SellerWallet/getBankAccountRequest', getBankAccountSaga),
  takeLatest('SellerWallet/deleteBankAccountRequest', deleteBankAccountSaga),

  // Transfers
  takeLatest('Transfers/transferRequest', transferRequestSaga),

  // Companies
  takeLatest('Companies/getCompanyRequest', getCompanyRequestSaga),
];
