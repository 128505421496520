import { takeLatest } from 'redux-saga/effects';

import {
  getCompaniesSaga,
  getCitiesSaga,
  getStatesSaga,
  getCompanyPublicDataSaga,
  getAddressRequestSaga,
  getRolesRequestSaga,
  createSellerRequestSaga,
  showCompanySagaRequest,
  updateCompanyRequest,
  approveSellerRequestSaga,
} from './AdminCompanies';

import {
  getRolesSaga,
  getPermissionsSaga,
  createRoleSaga,
  updateRoleSaga,
  deleteRoleSaga,
  showRoleSaga,
} from './AdminRoles';

import {
  createPaymentPlanRequestSaga,
  getPaymentPlansRequestSaga,
  showSelectedPlanSaga,
  updatePaymentPlanRequestSaga,
} from './AdminPayments';

import {
  getUsersRequestSaga,
  createUserRequestSaga,
  deleteUserRequestSaga,
  userFormFactoryRequestSaga,
  updateUserRequestSaga,
} from './AdminUsers';

import { getOrdersRequestSaga } from './AdminOrders';

import {
  getConsumersRequestSaga,
  getConsumerRequestSaga,
} from './AdminConsumers';

import {
  getTotalSellersRequestSaga,
  getTotalUsersRequestSaga,
  getTransactionsStatsRequestSaga,
  getTransactionsHistory,
  getSellersRankingSaga,
} from './AdminDashboard';

export default [
  /**
   * AdminCompanies
   */
  takeLatest('AdminCompanies/getCompaniesRequest', getCompaniesSaga),
  takeLatest('AdminCompanies/getCitiesRequest', getCitiesSaga),
  takeLatest('AdminCompanies/getStatesRequest', getStatesSaga),
  takeLatest('AdminCompanies/getCompanyDataRequest', getCompanyPublicDataSaga),
  takeLatest('AdminCompanies/getAddressRequest', getAddressRequestSaga),
  takeLatest('AdminCompanies/getRolesRequest', getRolesRequestSaga), // TODO: Rever esta função
  takeLatest('AdminCompanies/createSellerRequest', createSellerRequestSaga),
  takeLatest('AdminCompanies/showCompanyRequest', showCompanySagaRequest),
  takeLatest('AdminCompanies/updateCompanyRequest', updateCompanyRequest),
  takeLatest('AdminCompanies/approveSellerRequest', approveSellerRequestSaga),
  // Admin Roles
  takeLatest('AdminRoles/getRolesRequest', getRolesSaga),
  takeLatest('AdminRoles/getPermissionsRequest', getPermissionsSaga),
  takeLatest('AdminRoles/createRoleRequest', createRoleSaga),
  takeLatest('AdminRoles/updateRoleRequest', updateRoleSaga),
  takeLatest('AdminRoles/deleteRoleRequest', deleteRoleSaga),
  takeLatest('AdminRoles/showRoleRequest', showRoleSaga),

  // Admin Payments
  takeLatest(
    'AdminPayments/createPaymentPlanRequest',
    createPaymentPlanRequestSaga,
  ),
  takeLatest(
    'AdminPayments/getPaymentPlansRequest',
    getPaymentPlansRequestSaga,
  ),
  takeLatest('AdminPayments/showPaymentPlanRequest', showSelectedPlanSaga),
  takeLatest(
    'AdminPayments/updatePaymentPlanRequest',
    updatePaymentPlanRequestSaga,
  ),
  /**
   * Admin users
   */
  takeLatest('AdminUsers/getUsersRequest', getUsersRequestSaga),
  takeLatest('AdminUsers/createUserRequest', createUserRequestSaga),
  takeLatest('AdminUsers/deleteUserRequest', deleteUserRequestSaga),
  takeLatest('AdminUsers/userFormFactoryRequest', userFormFactoryRequestSaga),
  takeLatest('AdminUsers/updateUserRequest', updateUserRequestSaga),
  /**
   * Admin Orders Requests
   */
  takeLatest('AdminOrders/getOrdersRequest', getOrdersRequestSaga),
  /**
   * Admin Consumers Requests
   */
  takeLatest('AdminConsumers/getConsumersRequest', getConsumersRequestSaga),
  takeLatest('AdminConsumers/getConsumerRequest', getConsumerRequestSaga),
  /**
   * Admin Dashboard Requests
   */
  takeLatest(
    'AdminDashboard/populateDashboardRequest',
    getTotalSellersRequestSaga,
  ),
  takeLatest(
    'AdminDashboard/populateDashboardRequest',
    getTotalUsersRequestSaga,
  ),
  takeLatest(
    'AdminDashboard/populateDashboardRequest',
    getTransactionsStatsRequestSaga,
  ),
  takeLatest('AdminDashboard/populateDashboardRequest', getTransactionsHistory),
  takeLatest('AdminDashboard/populateDashboardRequest', getSellersRankingSaga),
];
