import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import api from '@services/api';
import { toastrRedux } from '@hooks/useToast';

import {
  createPaymentPlanError,
  createPaymentPlanSuccess,
  getPaymentPlansSuccess,
  getPaymentPlansError,
  showPaymentPlanSuccess,
  showPaymentPlanError,
  updatePaymentPlanSuccess,
  updatePaymentPlanError,
} from '@store/Reducers/Admin/AdminPayments';
import history from '@hooks/useHistory';

export function* createPaymentPlanRequestSaga({
  payload,
}: PayloadAction<Partial<IPayments.IPaymentPlan>>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.post, '/admin/payments/plans', payload);
    if (response.data) {
      yield put(createPaymentPlanSuccess());
      toastrRedux('Plano de pagamento criado com sucesso.', 'success');
      history.push('/admin/settings/payments-plans');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(createPaymentPlanError());
    }
  }
}

export function* getPaymentPlansRequestSaga(): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, '/admin/payments/plans');
    if (response.data) {
      yield put(getPaymentPlansSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(getPaymentPlansError());
    }
  }
}

export function* showSelectedPlanSaga({
  payload,
}: PayloadAction<number>): IRedux.ReduxCustom {
  try {
    const response = yield call(api.get, `/admin/payments/plans/${payload}`);
    if (response.data) {
      yield put(showPaymentPlanSuccess(response.data));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(showPaymentPlanError());
    }
  }
}

export function* updatePaymentPlanRequestSaga({
  payload,
}: PayloadAction<any>): IRedux.ReduxCustom {
  try {
    const { id, ...plan } = payload;
    const response = yield call(api.patch, `/admin/payments/plans/${id}`, plan);
    if (response.data) {
      yield put(updatePaymentPlanSuccess());
      toastrRedux('Plano de pagamento atualizado com sucesso.', 'success');
      history.push('/admin/settings/payments-plans');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      toastrRedux(error.message, 'error');
      yield put(updatePaymentPlanError());
    }
  }
}
