import React, {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import {
  loginRequest,
  logoutRequest,
  setData,
  clearState,
} from '@store/Reducers/Auth';
import { useAppDispatch, useAppSelector } from '@hooks/useRedux';
import api from '@services/api';
import { useToast } from './useToast';

type IAuthToken = {
  exp: number;
  iat: number;
};

type SignInCredentials = {
  email: string;
  password: string;
};

type AuthContextData = {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  updateUser: (user: IUser) => void;
  signOut(): void;
  can(permission: string): boolean;
};

type IProps = {
  children: React.ReactNode;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<IProps> = ({ children }) => {
  const { addToast } = useToast();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { data } = useAppSelector(state => state.Auth);

  const signIn = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      dispatch(loginRequest({ email, password }));
    },
    [dispatch],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@BateClickPay:token');
    localStorage.removeItem('@BateClickPay:user');

    dispatch(logoutRequest());

    navigate('/', {
      replace: true,
    });
  }, [dispatch, navigate]);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@BateClickPay:user', JSON.stringify(user));
      dispatch(
        setData({
          user,
          token: data.token,
        }),
      );
    },
    [dispatch, data.token],
  );

  const checkIfTokenIsValid = useCallback(
    (token: string) => {
      const decoded = jwtDecode<IAuthToken>(token);
      const { exp } = decoded;

      const expDate = new Date(exp * 1000);

      if (expDate < new Date(Date.now())) {
        signOut();
      }
    },
    [signOut],
  );

  /**
   * @param permission - Permission to check
   * @returns - True if user has permission, false otherwise
   * @description - Checks if user has permission
   * @example - can('user:users:create')
   */
  const userCan = useCallback(
    (permission: string) => {
      if (!permission) {
        addToast({
          message: 'Permissão não informada.',
          type: 'error',
        });

        return false;
      }

      if (data.user.role.is_admin) {
        return true;
      }

      const parsedPermission = permission.split(':');

      if (parsedPermission.length > 3) {
        addToast({
          message: 'Permissão inválida.',
          type: 'error',
        });
      }

      const { role } = data.user;
      const { permissions } = role;
      const { config } = permissions;

      let checkIfUserCan = false;
      Object.keys(config).forEach(module => {
        if (module === parsedPermission[0]) {
          if (config[module].data[parsedPermission[1]]) {
            checkIfUserCan =
              config[module].data[parsedPermission[1]].includes('full') ||
              config[module].data[parsedPermission[1]].includes(
                parsedPermission[2],
              );
          }
        }
      });

      return checkIfUserCan;
    },
    [addToast, data.user],
  );

  useEffect(() => {
    if (localStorage.getItem('@BateClickPay:token')) {
      const token = localStorage.getItem('@BateClickPay:token');
      const user = localStorage.getItem('@BateClickPay:user');
      if (token && user) {
        api.defaults.headers.common.authorization = `Bearer ${token}`;
        dispatch(setData({ token, user: JSON.parse(user) }));
      } else {
        dispatch(clearState());
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (data.token) {
      checkIfTokenIsValid(data.token);
    }
  }, [checkIfTokenIsValid, data]);

  const values = useMemo(
    () => ({ user: data.user, signIn, signOut, can: userCan, updateUser }),
    [data.user, signIn, signOut, updateUser, userCan],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { useAuth, AuthProvider };
