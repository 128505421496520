import AdminCompanies from './AdminCompanies';
import AdminRoles from './AdminRoles';
import AdminPayments from './AdminPayments';
import AdminUsers from './AdminUsers';
import AdminOrders from './AdminOrders';
import AdminConsumers from './AdminConsumers';
import AdminDashboard from './AdminDashboard';

export default {
  AdminCompanies,
  AdminRoles,
  AdminPayments,
  AdminUsers,
  AdminOrders,
  AdminConsumers,
  AdminDashboard,
};
