/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialState = {
  loading: boolean;
  errors: boolean;
  states: IGenericType[];
  cities: IGenericType[];
  address: ICEPData | undefined;
};

const initialState: IInitialState = {
  loading: false,
  errors: false,
  states: [],
  cities: [],
  address: undefined,
};

const publicData = createSlice({
  name: 'PublicData',
  initialState,
  reducers: {
    getStatesRequest: state => {
      state.loading = true;
    },
    getStatesSuccess: (state, action: PayloadAction<IGenericType[]>) => {
      state.loading = false;
      state.states = action.payload;
    },
    getStatesError: state => {
      state.loading = false;
      state.errors = true;
    },
    getCitiesRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getCitiesSuccess: (state, action: PayloadAction<IGenericType[]>) => {
      state.loading = false;
      state.cities = action.payload;
    },
    getCitiesError: state => {
      state.loading = false;
      state.errors = true;
    },
    getAddressRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getAddressSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.address = action.payload;
    },
    getAddressError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  getStatesRequest,
  getStatesError,
  getStatesSuccess,
  getCitiesRequest,
  getCitiesSuccess,
  getCitiesError,
  getAddressRequest,
  getAddressSuccess,
  getAddressError,
} = publicData.actions;

export default publicData.reducer;
