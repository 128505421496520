/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type IInitialState = {
  loading: boolean;
  errors: boolean;
  bankAccounts: IBankAccount[];
  bankAccount: IBankAccount;
  showDeleteModal: boolean;
};

const initialState: IInitialState = {
  loading: false,
  errors: false,
  bankAccounts: [] as IBankAccount[],
  bankAccount: {} as IBankAccount,
  showDeleteModal: false,
};

const wallet = createSlice({
  name: 'SellerWallet',
  initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    getBankAccountsRequest: state => {
      state.loading = true;
    },
    getBankAccountsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.bankAccounts = action.payload;
    },
    getBankAccountsError: state => {
      state.loading = false;
      state.errors = true;
    },
    createBankAccountRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createBankAccountSuccess: state => {
      state.loading = false;
    },
    createBankAccountError: state => {
      state.loading = false;
      state.errors = true;
    },
    getBankAccountRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    getBankAccountSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.bankAccount = action.payload;
    },
    getBankAccountError: state => {
      state.loading = false;
      state.errors = true;
    },
    deleteBankAccountConfirmation: state => {
      state.showDeleteModal = true;
    },
    cancelDeleteBankAccount: state => {
      state.showDeleteModal = false;
    },
    deleteBankAccountRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    deleteBankAccountSuccess: state => {
      state.loading = false;
      state.showDeleteModal = false;
    },
    deleteBankAccountError: state => {
      state.loading = false;
      state.errors = true;
      state.showDeleteModal = false;
    },
  },
});

export const {
  clearState,
  getBankAccountsRequest,
  getBankAccountsSuccess,
  getBankAccountsError,
  createBankAccountRequest,
  createBankAccountSuccess,
  createBankAccountError,
  getBankAccountRequest,
  getBankAccountSuccess,
  getBankAccountError,
  deleteBankAccountConfirmation,
  cancelDeleteBankAccount,
  deleteBankAccountRequest,
  deleteBankAccountSuccess,
  deleteBankAccountError,
} = wallet.actions;

export default wallet.reducer;
