/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ITransactionsHistory = {
  month: string;
  total: number;
};

type ISellersRanking = {
  seller_id: string;
  company_tax_number: string;
  company_company_name: string;
  total: number;
};

const INITIAL_STATE = {
  loading: false,
  errors: false,
  totalUsers: 0,
  totalSellers: 0,
  dailyTransactions: 0,
  monthlyTransactions: 0,
  transactionsHistory: [] as ITransactionsHistory[],
  ranking: [] as ISellersRanking[],
};

const adminDashboard = createSlice({
  name: 'AdminDashboard',
  initialState: INITIAL_STATE,
  reducers: {
    clearState: () => {
      return INITIAL_STATE;
    },
    populateDashboardRequest: state => {
      state.loading = true;
    },
    getTotalUsersSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.totalUsers = action.payload;
    },
    getTotalUsersError: state => {
      state.loading = false;
      state.errors = true;
    },
    getTotalSellersSuccess: (state, action: PayloadAction<number>) => {
      state.loading = false;
      state.totalSellers = action.payload;
    },
    getTotalSellersError: state => {
      state.loading = false;
      state.errors = true;
    },
    getTransactionsStatsRequest: state => {
      state.loading = true;
    },
    getTransactionsStatsSuccess: (
      state,
      action: PayloadAction<{ total_daily: number; total_monthly: number }>,
    ) => {
      state.loading = false;
      state.dailyTransactions = action.payload.total_daily;
      state.monthlyTransactions = action.payload.total_monthly;
    },
    getTransactionsStatsError: state => {
      state.loading = false;
      state.errors = true;
    },
    getTransactionsHistorySuccess: (
      state,
      action: PayloadAction<ITransactionsHistory[]>,
    ) => {
      state.loading = false;
      state.transactionsHistory = action.payload;
    },
    getTransactionsHistoryError: state => {
      state.loading = false;
      state.errors = true;
    },
    getSellersRankingSuccess: (
      state,
      action: PayloadAction<ISellersRanking[]>,
    ) => {
      state.loading = false;
      state.ranking = action.payload;
    },
    getTotalSellersRankingError: state => {
      state.loading = false;
      state.errors = true;
    },
  },
});

export const {
  clearState,
  populateDashboardRequest,
  getTotalUsersSuccess,
  getTotalUsersError,
  getTotalSellersSuccess,
  getTotalSellersError,
  getTransactionsStatsRequest,
  getTransactionsStatsSuccess,
  getTransactionsStatsError,
  getTransactionsHistorySuccess,
  getTransactionsHistoryError,
  getSellersRankingSuccess,
  getTotalSellersRankingError,
} = adminDashboard.actions;

export default adminDashboard.reducer;
